<template>
   <div class="LayoutArticle">
      <main class="LayoutArticle__main">
         <slot/>
      </main>
   </div>
</template>

<script>
   export default {
      name: `LayoutArticle`,
      components:{
      },      
      data() {
         return {
            user: null,
         };
      },
      created() {
         // Simulate fetching user data.
         setTimeout(() => {
            this.user = { name: `John Doe` };
         }, 2000);
      },
   }
</script>