<template>
   <section id="breadcrumbs" class=" striscia-bg-bg-a12" :class="[
      { ['striscia-bg']: showBackground },
   ]">
      <div id="breadcrumbs2" class="container py-2 d-print-none" style="padding-top: 0px;">
         <nav aria-label="breadcrumb" class="breadcrumb-container d-none d-sm-block ">
            <ol class="breadcrumb mb-0">
               <li class="breadcrumb-item"><a href="/" class="router-link-active"> Home</a></li>
               <li class="breadcrumb-item" v-for="(item, index) in parents" :key="index">
                  <router-link :to="{ path: item.url }">{{ item.name }}</router-link>
               </li>
               <li class="breadcrumb-item" v-if="last !== null">{{ last }}</li>
               <li class="breadcrumb-item" v-if="lastTest != ''">{{ lastTest }}</li>
            </ol>
         </nav>
      </div>
   </section>
</template>
<script>

export default {
   name: "Breadcrumbs",
   data() {
      return {
         parents: [],
         showBackground: false,
         last: null
      }
   },
   props: {
      lastTest: String,
   },
   beforeMount() {
      this.last = (this.$route.meta.breadcrumb != '' && this.$route.meta.layout !== undefined && this.$route.meta.layout.name === 'LayoutSection' ? this.$route.meta.breadcrumb : null)
      this.parents = this.$route.meta.parents
   },
   created() {
      this.last = (this.$route.meta.breadcrumb != '' && this.$route.meta.layout !== undefined && this.$route.meta.layout.name === 'LayoutSection' ? this.$route.meta.breadcrumb : this.$props.lastTest)
      this.parents = this.$route.meta.parents
      this.showBackground = this.$root.hasBackground()
   },
   beforeUpdate() {
      this.showBackground = this.$root.hasBackground()
   },
   mounted() {
      this.showBackground = this.$root.hasBackground()
   },
   updated() {
      this.showBackground = this.$root.hasBackground()
   }
};
</script>
<style scoped>
.breadcrumb {
   font-size: 0.77778rem;
   font-weight: 600;
}
</style>