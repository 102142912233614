<template>
   <header class="it-header-wrapper it-header-sticky vue-fixed-header" :class="[{'vue-fixed-header--isFixed' : ridotto}]">
      <div class="it-header-slim-wrapper">
         <div class="container px-0 px-sm-3">
            <div class="it-header-slim-wrapper-content">
               <a class="navbar-brand" href="https://www.regione.sardegna.it" title="Regione Autonoma della Sardegna">Regione Autonoma della Sardegna</a>
            </div>
         </div>
      </div>

      <div class="bg-white">
        <div class="container it-header-main">
           <div class="row">
              <div class="col-12 mt-2 mt-sm-0 mt-md-2 mb-0 mb-md-2 d-flex">
                 <button class="d-inline d-lg-none d-flex align-items-center custom-navbar-toggler" type="button" @click="expanded = !expanded">
                    <svg class="icon ">
                       <use xlink:href="@/assets/img/sprite.svg#it-burger"></use>
                    </svg>
                 </button>
                 <div class="align-middle d-flex align-items-center">
                    <h2 class="font-weight-bold appTitle">
                    <router-link :to="{ path: '/'}" class="text-black text-decoration-none " :title="this.app_title"><span v-html="this.$root.appname"></span></router-link></h2>
                 </div>
                 <div class="d-inline d-lg-none mt-auto mb-auto ml-auto">
                    <a href="#" @click="!isSearch ? $refs.searchGeneric.toggleModal()  : null">
                       <span class="fa-stack fa-fw">
                         <i class="fa fa-circle fa-fw fa-stack-2x fa-stack-2x primary-color"></i>
                         <i class="fa fa-search fa-fw fa-stack-1x fa-inverse"></i>
                       </span>
                    </a>
                 </div>
                 <div class="small align-self-center d-none d-lg-block ml-auto">
                    <a href="https://www.regione.sardegna.it/" class="d-none d-lg-inline" target="_blank"><img src="@/assets/img/ras-logo.svg" alt="Regione Autonoma della Sardegna" width="180" height="79" style="max-width: 180px;"/></a>
                    <h2 class="h4 small font-weight-bold mb-0"><a href="https://www.regione.sardegna.it/" class="d-inline d-md-none" target="_blank">Regione Autonoma della Sardegna</a></h2>
                 </div>
              </div>
           </div>
        </div>
      </div>

      <div class="it-header-navbar-wrapper bg-white">
         <div class="container">
            <div class="row">
               <div class="col-12 d-flex justify-content-between">
                  <nav class="navbar navbar-expand-lg has-megamenu">
                     <div class="navbar-collapsable" id="nav10" v-bind:class="{ 'expanded d-block': isActive }">
                        <div class="overlay" @click="expanded = !expanded"></div>
                        <div class="close-div">
                           <!-- img src="@/assets/img/ras-logo.svg" alt="Regione Autonoma della Sardegna" style="max-width: 150px;" class="pl-3 pt-3 d-inline d-inline d-lg-none"/ -->
                           <router-link :to="{ path: '/'}" class="text-black text-decoration-none " :title="this.app_title">
                              <h2 class="font-weight-bold appTitle-mob">
                                <span v-html="this.$root.appname"></span>
                             </h2>
                           </router-link>
                           <button class="btn close-menu d-inline d-lg-none" type="button" @click="expanded = !expanded" >
                              <svg class="icon"><use xlink:href="@/assets/img/sprite.svg#it-close-big "></use></svg>
                           </button>
                        </div>
                        <div class="menu-wrapper">
                           <main-menu :top="true" :menuList="menuList" :parentSelected="this.$route.meta.parents"  @close-menu="onClickChild"/>
                        </div>
                     </div>                               
                  </nav>

                  <div class="d-none d-lg-flex">
                     <div class="d-flex align-items-center it-search-wrapper" @click="!isSearch ? $refs.searchGeneric.toggleModal()  : null">
                        <span class="d-none d-md-block x-small mr-1">Cerca</span>
                        <div class="search-link rounded-icon">
                           <a class="search-link rounded-icon" name="apriRicerca" >
                              <svg class="icon">
                                 <use xlink:href="@/assets/img/sprite.svg#it-search"></use>
                              </svg>
                           </a>
                        </div> 
                     </div>     
                  </div>

               </div>
            </div>
         </div>
      </div>
      <searchGeneric v-bind:where="`header`" v-bind:title="`Cerca`" ref="searchGeneric"/>
   </header>
</template>
<script>
import MainMenu from './MainMenu';
import SearchGeneric from "@/components/SearchGeneric.vue";

export default {
   name: 'AppHeader',
   components:{
      MainMenu,
      SearchGeneric,
   },
   props:{
      threshold: {
        type: Number,
        description: "threshold before reducing header"         
      },
      menuList:{
        type: [Array, Object],
        description: "MainMenu array",
        required: true
     }
   },
   beforeUnmount: function () {
      window.removeEventListener('scroll', this.pageScrollListener)
   },
   computed: {
      cssVars () {
        return{
          '--threshold': this.threshold,
        }         
      },

      isActive() {
         return this.expanded;
      },

      isSearch() {
        return this.$route.name === 'Cerca'
      }
   },   

   data() {
      return {
         app_title: process.env.VUE_APP_TITLE,
         ridotto: false,
         expanded: false,
         active: false,
         query: '',         
      }
   },   

   methods: {
      pageScrollListener: function () {
         this.ridotto = window.scrollY > this.threshold
      },

      onClickChild () {
         this.expanded = false;
         this.active = false;
      },
   },

   mounted: function () {
      window.addEventListener('scroll', this.pageScrollListener)
   },

   watch: {
      ridotto(value){
         this.$emit('emitScrolled', value);
      },
      expanded(to){
         if(to){
            document.body.classList.add('fixed')
         }
         else{
            document.body.classList.remove('fixed')
         }
      }
   }
};
</script>
<style>
  .it-header-wrapper.it-header-sticky.vue-fixed-header .navbar .navbar-collapsable ul.navbar-nav li.nav-item:nth-child(2) a{ padding-left:0px !important; }
  .it-header-wrapper.it-header-sticky.vue-fixed-header--isFixed .navbar .navbar-collapsable ul.navbar-nav li.nav-item:nth-child(2) a{ padding-left:24px !important; }

   @media screen and (max-width: 992px) {
      .it-header-wrapper.it-header-sticky.vue-fixed-header .navbar .navbar-collapsable ul.navbar-nav li.nav-item:nth-child(2) a{padding-left:24px !important; }
      .it-header-wrapper.it-header-sticky.vue-fixed-header .navbar .navbar-collapsable ul.navbar-nav li.nav-item a{margin-top:8px !important;margin-bottom:8px !important; }
      .it-header-wrapper.it-header-sticky.vue-fixed-header .navbar .navbar-collapsable .menu-wrapper .navbar-nav{ border-top:6px solid #023047 }
      .it-header-wrapper.it-header-sticky.vue-fixed-header--isFixed .navbar .navbar-collapsable .navbar-nav li a.nav-link{padding: 10px 10px 4px 10px;}
      .it-header-wrapper.it-header-sticky.vue-fixed-header--isFixed .navbar .navbar .close-div{padding:24px 0px 12px 0px !important;}
      .it-header-wrapper.it-header-sticky.vue-fixed-header--isFixed .navbar .navbar .close-div .close-menu{ position: absolute; top:24px; right: 16px; }
      .navbar .navbar-collapsable.expanded.d-block .menu-wrapper{ height:100vh; }
      .scrolled .appTitle{ font-size:18px; margin-bottom: 2px; }
      .close-menu{ position: absolute; top:24px; right: 16px; }
      #formSearchGeneric h4{ margin-bottom:48px; }
      .appTitle-mob{ padding-left:24px; }
      .appTitle-mob span+b{display:block; clear:left;}
   }
   @media screen and (max-width: 767px) {
      .search-area .modal .modal-dialog {max-width:100%;}
      #formSearchGeneric .input-group{ margin-top:84px; }
   }   
</style>