<template>  
   <div class="it-footer-small-prints clearfix d-print-none">
      <div class="container">
         <div class="d-flex flex-column flex-sm-row flex-wrap mt-3 justify-content-between font-weight-semibold">
            <ul class="it-footer-small-prints-list list-inline mb-0 py-3 px-0">
               <template v-for="item in miniFooterMenuList" :key="item.idContenuto">
                 <li class="list-inline-item d-block d-sm-inline" v-if="item.attivo">
                    <a v-if="item.tipo == 'ESTERNO'" :href="item.linkUrl" class="text-black" target="_blank">{{ item.nome }}</a>
                    <router-link class="text-black" v-else
                       :to="{
                          path: (item.linkUrl === '/undefined' ? '/'+this.$root.slugify(item.nome) : item.linkUrl),
                          props:{
                             id: item.idFonteDati,
                             tipo: item.tipo
                          }
                       }"
                       >
                       {{ item.nome }}
                    </router-link>
                 </li>
               </template>
            </ul>

            <div class="text-left text-sm-end  pt-0 pb-3 py-sm-3 text-black">
               &copy; {{year}} Regione Autonoma della Sardegna
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name: 'MiniFooter',
   props:{
      miniFooterMenuList:{
        type: [Array, Object, null],
        description: "MiniFooter Menu array",
        required: true
     }
   },
   created(){

   },
   data() {
      return {
         menu: this.$store.getters.getMiniFooterMenu,
         year: new Date().getFullYear(),
      }
   }      
}
</script>

