<template>
   <div class="LayoutSection">
      <main class="LayoutSection__main">
         <slot/>
      </main>
   </div>
</template>

<script>
   export default {
      name: `LayoutSection`,
      components:{
      },
      data() {
         return {
            user: null,
         };
      }
   }
</script>