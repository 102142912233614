<template>  
   <footer class="it-footer mt-1"  :class="[
      {['mt-4'] : this.$root.lastChild !== '404 Pagina non trovata' }
    ]">
      <div class="it-footer-main">
         <div class="container">
            <div class="row clearfix">
               <div class="col-sm-12">
                  <div class="it-brand-wrapper pt-2">
                     <ul class="nav list-inline d-flex justify-content-between footer-icons">
                        <li class="list-inline-item mr-0 pt-2">
                           <router-link :to="{ name: 'Home' }" class="h3 mr-0 pt-2 text-white">
                              <b v-html="this.$root.appname"></b>
                           </router-link>
                        </li>
                     </ul>              
                  </div>
               </div>
            </div>
         </div>
         <div class="container">
            <div class="row">
               <FooterMenuBlock v-for="(item, index) in this.$store.getters.getFooterMenu" :block="item" :key="index" />
            </div>
         </div>
         <div class="container mt-3 mb-4">
            <a href="https://www.regione.sardegna.it" title="Regione Autonoma della Sardegna"><img src="@/assets/img/ras-logo-negativo.svg" width="170" height="100" alt="logo Regione Autonoma della Sardegna"/></a>
         </div>
         <MiniFooter :miniFooterMenuList="this.$store.getters.getMiniFooterMenu" />
      </div>
   </footer>    

</template>

<script>
import FooterMenuBlock from './FooterMenuBlock';   
import MiniFooter from './MiniFooter';   

export default {
   name: 'AppFooter',
   components: {
      MiniFooter,
      FooterMenuBlock    
   },

   data() {
      return {         
      }
   },

   props:{
   },  

   methods:{
   },   

   created(){
   },
   
   beforeMount: function(){
   },   

   mounted: function(){
   },

   beforeUpdate: function(){
   },   

   updated: function(){
   },

   watch:{
   }
}
</script>
<style>
   .footer-icons img{ min-width:100px; }
</style>