<template>
   <!-- ul class="link-list-wrapper d-flex justify-content-between p-0 m-0"-->

   <ul class="navbar-nav navbar-nav-light"
      :class="[
         {'footer-list': bottom},
      ]" >
      <li><h2 class="font-weight-bold h4 mt-1 mb-0"><router-link :to="{ path: '/'}" class="text-black text-decoration-none " :title="appName"><span v-html="this.$root.appname"></span></router-link></h2></li>

      <template v-for="(item, name, index) in menuList" :key="index">  
         <li class="nav-item font-weight-semibold" v-if="item.attivo">                                 
            <a v-if="item.tipo == 'ESTERNO'" :href="item.linkUrl" class="nav-link" target="_blank">{{ item.nome }}</a>            
            <router-link v-else
               :to="{
                  //name: this.$root.namedLink(item.nome),
                  path: (item.linkUrl === '/undefined' ? '/'+this.$root.slugify(item.nome) : item.linkUrl),
                  props:{
                     titolo: item.titolo,
                     nome: item.nome,
                     id: item.idFonteDati,
                     tipo: item.tipo
                  },                                 
               }" 
               class="nav-link"
               :class="{ active: item.nome == parentSelect }"               
               active-class="active"
               @click="$emit('closeMenu')" 
            >            
               {{ item.nome }}
            </router-link>         
         </li>   
      </template>
   </ul>   
</template>
<script>
export default {
   name: "main-menu",
   props: {
      type: {
         type: String,
         description: "Image"
      }, 
      menuList: {
         type: Array,
         description: "menuItems in object"
      },      
      levels: {
         type: Number,
         default: 1,
         description: "Number of levels"
      }, 
      parentSelected: {
         type: Array
      },
      bottom: {
         type: Boolean,
         default: false,
         description: "Number of levels"
      }       
   },
   data(){
      return{
         appName: process.env.VUE_APP_TITLE,
         parentSelect: "",
      }   
   },

   updated(){
      if(this.$route.meta.parents !== undefined && this.$route.meta.parents.length > 0){
         this.parentSelect = this.$route.meta.parents[0].name
      }
      else{
         this.parentSelect = null
      }
   }
};
</script>
<style scoped>
.mainMenu ul li.list-inline-item:not(:last-child){ margin:0px; }
.mainMenu ul li:first-child a{ padding-left: 0px;}
.mainMenu ul li:last-child a{ padding-right: 0px;} 
header.it-header-sticky.vue-fixed-header ul.navbar-nav li:first-child{display:none;}
.scrolled header.it-header-sticky.vue-fixed-header ul.navbar-nav li:first-child{display:inline;}
</style>