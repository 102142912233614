<template>  
   <div class="pb-2 col-sm-6 col-md-6 col-lg-3 my-3 my-md-0" v-if="block.attivo">
      <h4 class="h6 text-uppercase border-bottom-block font-weight-semibold pb-2 position-relative">
         <router-link v-if="block.linkUrl" :to="{
            path: block.linkUrl,
            param:{
               id: block.idFonteDati,               
            },     
            props:{
               id: block.idFonteDati,               
            },                          
         }">{{ block.nome }}</router-link>
         <span v-else>
            {{ block.nome }}
         </span>
      </h4>
      <div class="link-list-wrapper" v-if="block.menuItemList">
         <ul class="footer-list clearfix link-list" >
            <template v-for="(item, index) in block.menuItemList" :key="index">  
               <li class="list-inline-item d-block"  :title="'Vai alla pagina'" v-if="item.attivo">
                  <router-link
                     :to="{
                        path: (item.linkUrl === '/undefined' ? '/'+this.$root.slugify(item.nome) : item.linkUrl),
                        props:{
                           titolo: item.titolo,
                           nome: item.nome,
                           id: item.idFonteDati,
                           tipo: item.tipo
                        }
                     }" 
                     class="nav-link"
                     active-class="active"
                     exact-path
                  >
                     {{item.nome}}
                  </router-link>
               </li>
            </template>
         </ul>
      </div>
   </div>
</template>
<script>
export default {
   name: 'FooterMenuBlock',
   props:{
      block:{
        type: Object,
        description: "Menu object",
        required: true
      }
   },
   computed:{

   }
}
</script>
